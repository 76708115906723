<script>
  import FaFacebook from 'svelte-icons/fa/FaFacebook.svelte';
  import FaLinkedin from 'svelte-icons/fa/FaLinkedin.svelte';

	const socials = [
		{ logo: '𝕏', url: 'https://twitter.com/spreadsheetsusa' },
		// { component: FaFacebook, url: 'https://www.facebook.com/spreadsheetsusa' },
		// { component: FaLinkedin, url: 'https://www.linkedin.com/company/spreadsheetsusa' }
	];
</script>

<div
	class="flex flex-wrap pt-6 text-center items-center mx-3 md:mx-14 transition-all md:border-b-[1px] border-[#96796f] pb-3"
>
	<div class="flex-grow w-full md:w-1/3 text-center md:text-left mb-3 md:mb-0">
		<div class="text-sm block opmono-bold font-['Operator_Mono_Bold']">The American Spreadsheet Company</div>
		<div class="font-['Operator_Mono_Book_Italic'] text-xs text-[#a88b85] font-light">
			Professionally Creative Knowledge Workers
		</div>
	</div>

	<!-- <div class="w-full md:w-1/3 md:flex-grow-0 md:border-l-[1px] md:border-r-[1px] border-[#96796f] px-3 md:mx-4 md:py-1 py-4 transition-all">
		<div class="flex items-center justify-center space-x-6 transition-all duration-200">
			{#each socials as social}
				<div class="w-8 h-8 md:w-6 md:h-6">
					<a href={social.url} target="_blank" rel="noopener noreferrer">
						{#if social.logo}
							<span class="text-3xl relative top-[-3px] leading-none">{social.logo}</span>
						{:else}
							<svelte:component this={social.component} />
						{/if}
					</a>
				</div>
			{/each}
		</div>
	</div> -->

	<div class="flex-grow space-y-1 text-center  text-md md:text-xs md:space-y-0 md:text-right">
		<span class="font-['Operator_Mono_Book_Italic'] text-[#efddc7]">
			<a href="mailto:services@spreadsheetsusa.com" class="underline md:no-underline">services@spreadsheetsusa.com</a>
		</span>
		<span class="contact-phone font-['Operator_Mono_Book'] block text-[#efddc7]">
			<a href="https://twitter.com/spreadsheetsusa" target="_blank" rel="noopener noreferrer" class="">𝕏</a>
			<span class="opacity-40">|</span>
			<a href="tel:510.387.4875" class="underline md:no-underline">510.387.4875</a>
		</span>
	</div>
</div>
