<h1 class="text-3xl sm:text-5xl md:text-6xl text-[#f6f6f6] font-bold text-center mb-9 md:mb-12 px-4 leading-relax transition-all max-w-[600px] mx-auto" style="text-wrap: balance;">Call on us when You need to pivot.</h1>

<!-- <div class="flex flex-wrap items-start justify-between max-w-5xl px-6 pb-12 mx-auto md:px-12 rounded-b-2xl pt-9 md:pt-12" style="background: linear-gradient(174deg, rgb(48 28 36 / 67%) 0%, rgb(70, 48, 56) 100%);">
  <div class="w-full md:w-1/2 md:pl-5">
    <p class="mb-6"><strong>The American Spreadsheet Company</strong> is your go-to partner in creative solutioning.</p>
    <p class="mb-3">We leverage open standards and run on a few <a href="/" class="text-[#5cd9a5]">guiding principles</a> that guarantee exceptional results.</p>
  </div>
  <div class="flex flex-col-reverse w-full mt-3 mr-5 md:flex-col md:mt-0 md:w-1/3">
    <a href="/" class="block bg-[#37916c] rounded-lg shadow-xl py-4 px-3 text-center mb-9 text-white text-xl font-semibold mt-6 md:mt-auto">
      🤙 &nbsp; Get SUM & CALC out
    </a>
    <ul class="ml-6 list-disc text-[#feeee3a7] space-y-1 mb-3">
      <li>Retail POS and E-Commerce</li>
      <li>Crypto & Finance Software</li>
      <li>Mobile App Development</li>
      <li>Education & Student Management</li>
      <li>Transportation and Logistics</li>
      <li>Membership Systems</li>
    </ul>
  </div>
</div> -->
