<script>
  // import { onMount } from 'svelte';

  // import { browser } from '$app/environment';

  import Header from '$lib/components/Header.svelte';
  import SplashLogo from '$lib/components/SplashLogo.svelte';
  import LeadInPitch from '$lib/components/LeadInPitch.svelte';
  // import IntegrationsPitch from '$lib/components/IntegrationsPitch.svelte';
  // import JumpInto from '$lib/components/JumpInto.svelte';
  // import CelebratingExperience from '$lib/components/CelebratingExperience.svelte';
  // import ListOfServicesContact from '$lib/components/ListOfServicesContact.svelte';

  // onMount(() => {
  //   if (browser) document.body.style.backgroundImage = 'url(/images/tasc-bg-light.jpg)';
  //   return () => document.body.style.backgroundImage = '';
  // });
</script>

<div class="flex justify-center items-start p-2.5 w-full">
  <div
    class="
      md:max-w-5xl
      w-full
      bg-[#492b36]
      bg-[length:200%]
      bg-[url('/images/header-splash-bg.jpg')]
      bg-no-repeat
      bg-top
      sm:mx-4
      md:bg-[length:120%]
      md:mt-6
      md:outline-8
      md:outline
      md:outline-[rgba(26,26,26,.16)]
      transition-all
      ease-in-out
      duration-300
      rounded-2xl
    "
  >
    <Header />
    <SplashLogo />
    <LeadInPitch />
  </div>
</div>

<div class="flex items-center justify-center text-center mt-3 text-xs text-[#635b55] mb-9">
  <p class="px-3">© {new Date().getFullYear()} The American Spreadsheet Company. <br class="md:hidden" />All Rights Reserved.</p>
</div>


<style>
  :global(body) {
    background-image: url(/images/tasc-bg-light.jpg);
    @apply mb-3 bg-center bg-cover bg-repeat text-[#fff0e4];
  }
</style>
